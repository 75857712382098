const forms = document.querySelectorAll('form:not(.modal__form)')

forms.forEach(form => {
  form.addEventListener('submit', e => {
    e.preventDefault()

    const jsonFormData = Object.fromEntries(new FormData(form))

    const additionalFields = ['target']

    additionalFields.forEach(field => {
      const value = form.getAttribute('data-' + field)

      if (value && value !== '') {
        jsonFormData[field] = value
      }
    })

    // add utms to jsonFormData

    const utms = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'utm_referrer'
    ]

    const urlParams = new URLSearchParams(window.location.search)

    utms.forEach(utm => {
      // get from url
      const value = urlParams.get(utm)

      if (value && value !== '') {
        jsonFormData[utm] = value
      }
    })

    // add location to jsonFormData

    const location = window.location.href

    jsonFormData['location'] = location

    // to formdata

    const formData = new FormData()

    for (const key in jsonFormData) {
      formData.append(key, jsonFormData[key])
    }

    // check phone field length

    const phone = jsonFormData['phone']

    if (phone.length < 18) {
      form.classList.add('is-error')

      return
    } else {
      form.classList.remove('is-error')
    }

    fetch('https://crimeamorozova.ru/amo/amo.php', {
      method: 'POST',
      body: formData
    })
      .then(response => response.text())
      .then(data => {
        if (data !== 'ok!') alert('Ошибка отправки формы')

        ym(96060546, 'reachGoal', 'lead_sent')

        document.querySelectorAll('.modal').forEach(modal => {
          modal.classList.remove('is-active')
        })
        document.querySelector('.modal.thanks').classList.add('is-active')
        document.querySelector('.modals').classList.add('is-active')

        setTimeout(() => {
          document.querySelector('.modal.thanks').classList.remove('is-active')
          document.querySelector('.modals').classList.remove('is-active')
        }, 1000 * 10)
      })
      .catch(error => {
        console.error(error)
      })
  })
})
