const videos = [
  {
    video: new URL('../../videos/video-1.mp4', import.meta.url),
    poster: new URL('../../images/swiper-1.png?as=webp', import.meta.url)
  },
  {
    video: new URL('../../videos/video-2.mp4', import.meta.url),
    poster: new URL('../../images/swiper-2.png?as=webp', import.meta.url)
  },
  {
    video: new URL('../../videos/video-3.mp4', import.meta.url),
    poster: new URL('../../images/swiper-3.png?as=webp', import.meta.url)
  }
]

const playImage = new URL('../../images/play.svg', import.meta.url)

const swiperItems = document.querySelectorAll('.reviews-swiper-item')

videos.forEach((video, index) => {
  swiperItems[index].querySelector(
    '.reviews-swiper-item__overlay'
  ).style.backgroundImage = `url(${video.poster})`
})

const doSwiper = () => {
  const swiperItems = document.querySelectorAll('.reviews-swiper-item')

  videos.forEach((video, index) => {
    const button = swiperItems[index].querySelector(
      '.reviews-swiper-item__button'
    )

    button.addEventListener('click', () => {
      if (swiperItems[index].classList.contains('is-active')) {
        const overlay = swiperItems[index].querySelector(
          '.reviews-swiper-item__overlay'
        )
        overlay.remove()

        const video = swiperItems[index].querySelector('video')
        video.play()
      }
    })
  })
}

const arrows = document.querySelectorAll('.reviews-swiper-arrow')

const prevArrow = arrows[0]
const nextArrow = arrows[1]

let index = 0

doSwiper()

const swipe = (prev = false) => {
  const swiperItems = document.querySelectorAll('.reviews-swiper-item')

  if (prev) {
    index--
  } else {
    index++
  }

  if (index > 2) {
    index = 0
  }

  if (index < 0) {
    index = 2
  }

  const firstItem = swiperItems[0]
  const centerItem = swiperItems[1]
  const lastItem = swiperItems[2]

  if (!centerItem.querySelector('.reviews-swiper-item__overlay')) {
    const code = `
      <div class="reviews-swiper-item__overlay" style="">
        <button class="reviews-swiper-item__button">
          <img src="${playImage}" alt=">">
        </button>
      </div>
      `

    centerItem.insertAdjacentHTML('beforeend', code)
  }

  switch (index) {
    case 0:
      // поставить фоны и src и видео на нужные
      firstItem
        .querySelector('.reviews-swiper-item__overlay')
        ?.setAttribute('style', `background-image: url(${videos[0].poster}) `)

      centerItem
        .querySelector('.reviews-swiper-item__overlay')
        ?.setAttribute('style', `background-image: url(${videos[1].poster}) `)

      lastItem
        .querySelector('.reviews-swiper-item__overlay')
        ?.setAttribute('style', `background-image: url(${videos[2].poster}) `)

      // поставить видео на нужные

      firstItem.querySelector('video').src = videos[0].video

      centerItem.querySelector('video').src = videos[1].video

      lastItem.querySelector('video').src = videos[2].video

      // поставить классы на нужные

      firstItem.classList.remove('is-active')
      centerItem.classList.add('is-active')
      lastItem.classList.remove('is-active')

      break

    case 1:
      // поставить фоны и src и видео на нужные
      firstItem
        .querySelector('.reviews-swiper-item__overlay')
        ?.setAttribute('style', `background-image: url(${videos[1].poster}) `)

      centerItem
        .querySelector('.reviews-swiper-item__overlay')
        ?.setAttribute('style', `background-image: url(${videos[2].poster}) `)

      lastItem
        .querySelector('.reviews-swiper-item__overlay')
        ?.setAttribute('style', `background-image: url(${videos[0].poster}) `)

      // поставить видео на нужные

      firstItem.querySelector('video').src = videos[1].video

      centerItem.querySelector('video').src = videos[2].video

      lastItem.querySelector('video').src = videos[0].video

      // поставить классы на нужные

      firstItem.classList.remove('is-active')
      centerItem.classList.add('is-active')
      lastItem.classList.remove('is-active')

      break

    case 2:
      // поставить фоны и src и видео на нужные
      firstItem
        .querySelector('.reviews-swiper-item__overlay')
        ?.setAttribute('style', `background-image: url(${videos[2].poster}) `)

      centerItem
        .querySelector('.reviews-swiper-item__overlay')
        ?.setAttribute('style', `background-image: url(${videos[0].poster}) `)

      lastItem
        .querySelector('.reviews-swiper-item__overlay')
        ?.setAttribute('style', `background-image: url(${videos[1].poster}) `)

      // поставить видео на нужные

      firstItem.querySelector('video').src = videos[2].video

      centerItem.querySelector('video').src = videos[0].video

      lastItem.querySelector('video').src = videos[1].video

      // поставить классы на нужные

      firstItem.classList.remove('is-active')

      centerItem.classList.add('is-active')

      lastItem.classList.remove('is-active')

      break
  }

  // удалить эти слайды и пересоздать их

  const cloneFirstItem = firstItem.cloneNode(true)
  const cloneCenterItem = centerItem.cloneNode(true)
  const cloneLastItem = lastItem.cloneNode(true)

  const core = document.querySelector('.reviews-swiper__core')

  core.innerHTML = ''

  const newSwiperItems = [cloneFirstItem, cloneCenterItem, cloneLastItem]

  newSwiperItems.forEach(item => {
    document.querySelector('.reviews-swiper__core').append(item)
  })

  doSwiper()
}

prevArrow.addEventListener('click', () => {
  swipe(true)
})

nextArrow.addEventListener('click', () => {
  swipe()
})
