//  phone inputs

const eventCallback = e => {
  let el = e.target

  if (!el.matches('input[type="tel')) return

  let clearVal = el.dataset.phoneClear,
    pattern = el.dataset.phonePattern,
    matrix_def = '+7 (___) ___-__-__',
    matrix = pattern ? pattern : matrix_def,
    i = 0,
    def = matrix.replace(/\D/g, ''),
    val = e.target.value.replace(/\D/g, '')
  if (clearVal !== 'false' && e.type === 'blur') {
    if (val.length < matrix.match(/([\_\d])/g).length) {
      e.target.value = ''
      return
    }
  }
  if (def.length >= val.length) val = def
  e.target.value = matrix.replace(/./g, function (a) {
    return /[_\d]/.test(a) && i < val.length
      ? val.charAt(i++)
      : i >= val.length
      ? ''
      : a
  })
}
document.addEventListener('input', e => eventCallback(e))

document.addEventListener('click', e => {
  if (!e.target.closest('input[type="tel"]')) return

  let el = e.target
  el.value = '+7 '
})