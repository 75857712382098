const triggers = document.querySelectorAll('[data-modal]')

triggers.forEach(trigger => {
  trigger.addEventListener('click', () => {
    const modal = document.querySelector(trigger.dataset.modal)
    modal.classList.add('is-active')

    const target = trigger.getAttribute('data-target')
    const city = trigger.getAttribute('data-city')
    const rooms = trigger.getAttribute('data-rooms')
    const mortgage = trigger.getAttribute('data-mortgage')

    if (target) {
      modal.setAttribute('data-target', target)
    } else {
      modal.setAttribute('data-target', '')
    }

    if (city) {
      modal.setAttribute('data-city', city)
    } else {
      modal.setAttribute('data-city', '')
    }

    if (rooms) {
      modal.setAttribute('data-rooms', rooms)
    } else {
      modal.setAttribute('data-rooms', '')
    }

    if (mortgage) {
      modal.setAttribute('data-mortgage', mortgage)
    } else {
      modal.setAttribute('data-mortgage', '')
    }

    const substrate = document.querySelector('.modals')
    substrate.classList.add('is-active')
  })
})

const substrate = document.querySelector('.modals')

substrate.addEventListener('click', e => {
  if (!e.target.matches('.modals')) {
    return
  }

  const modals = document.querySelectorAll('.modal')
  modals.forEach(modal => {
    modal.classList.remove('is-active')

    modal.setAttribute('data-target', '')
    modal.setAttribute('data-city', '')
    modal.setAttribute('data-rooms', '')
    modal.setAttribute('data-mortgage', '')
  })

  substrate.classList.remove('is-active')
})

// radios selector

const radios = document.querySelectorAll('.estate-col__checkbox input')

const estate = document.querySelector('.estate')
const button = estate.querySelector('button')

radios.forEach(radio => {
  radio.addEventListener('change', () => {
    const name = radio.getAttribute('name')

    const value = radio.getAttribute('value')

    let newName

    switch (name) {
      case 'город':
        newName = 'city'
        break

      case 'комнаты':
        newName = 'rooms'
        break

      case 'ипотека':
        newName = 'mortgage'
        break

      default:
        newName = '' // Значение по умолчанию, если name не соответствует ни одному из случаев
    }

    button.setAttribute('data-' + newName, value)
  })
})

const modalForms = document.querySelectorAll('.modal__form')

modalForms.forEach(form => {
  const modal = form.closest('.modal')

  form.addEventListener('submit', e => {
    e.preventDefault()

    const jsonFormData = Object.fromEntries(new FormData(form))

    const additionalFields = ['target', 'city', 'rooms', 'mortgage']

    additionalFields.forEach(field => {
      const value = modal.getAttribute('data-' + field)

      if (value && value !== '') {
        jsonFormData[field] = value
      }
    })

    // add utms to jsonFormData

    const utms = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'utm_referrer'
    ]

    const urlParams = new URLSearchParams(window.location.search)

    utms.forEach(utm => {
      // get from url
      const value = urlParams.get(utm)

      if (value && value !== '') {
        jsonFormData[utm] = value
      }
    })

    // add location to jsonFormData

    const location = window.location.href

    jsonFormData['location'] = location

    // to formdata

    const formData = new FormData()

    for (const key in jsonFormData) {
      formData.append(key, jsonFormData[key])
    }

    // check phone field length

    const phone = jsonFormData['phone']

    if (phone.length < 18) {
      modal.classList.add('is-error')

      return
    } else {
      modal.classList.remove('is-error')
    }

    fetch('https://crimeamorozova.ru/amo/amo.php', {
      method: 'POST',
      body: formData
    })
      .then(response => response.text())
      .then(data => {
        if (data !== 'ok!') alert('Ошибка отправки формы')

        ym(96060546, 'reachGoal', 'lead_sent')

        document.querySelectorAll('.modal').forEach(modal => {
          modal.classList.remove('is-active')
        })
        document.querySelector('.modal.thanks').classList.add('is-active')
        document.querySelector('.modals').classList.add('is-active')

        setTimeout(() => {
          document.querySelector('.modal.thanks').classList.remove('is-active')
          document.querySelector('.modals').classList.remove('is-active')
        }, 1000 * 10)
      })
      .catch(error => {
        console.error(error)
      })
  })
})
