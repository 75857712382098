const zoomableImages = document.querySelectorAll('img[data-zoomable]')


const zoomableWrapper = document.querySelector('.zoomable')

zoomableImages.forEach(image => {
    image.addEventListener('click', () => {
      const imageElement = document.createElement('img')

      imageElement.src = image.src

      zoomableWrapper.appendChild(imageElement)

      zoomableWrapper.classList.add('is-active')
    })
})

zoomableWrapper.addEventListener('click', () => {
    zoomableWrapper.classList.remove('is-active')
    zoomableWrapper.innerHTML = ''
})