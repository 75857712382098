const ourWorkVideoButton = document.querySelector('.our-work-video__button')

if (ourWorkVideoButton) {
  const ourWorkVideoOverlay = ourWorkVideoButton.closest('.our-work-video__overlay')
  ourWorkVideoButton.addEventListener('click', () => {
    ourWorkVideoOverlay.remove()

    const main = document.querySelector('.our-work-video')

    const video = document.createElement('video')
    video.src = './assets/videos/our-work.mp4'
    video.autoplay = true
    video.loop = true
    video.muted = true

    video.style.width = '100%'
    video.style.height = '100%'

    main.appendChild(video)
  })
}

const agencyVideoButton = document.querySelector('.agency-info-video__overlay')

if (agencyVideoButton) {
  const agencyVideoOverlay = agencyVideoButton.closest('.agency-info-video__overlay')
  agencyVideoButton.addEventListener('click', () => {
    agencyVideoOverlay.remove()

    const main = document.querySelector('.agency-info-video__wrap')

    const video = document.createElement('video')
    video.src = './assets/videos/agency.mp4'
    video.autoplay = true
    video.controls = true

    video.style.width = '100%'
    video.style.height = '100%'

    main.appendChild(video)
  })
}

const gettingVideoButton = document.querySelector('.getting-video__button')

if (gettingVideoButton) {
  const gettingVideoOverlay = gettingVideoButton.closest('.getting-video__overlay')
  gettingVideoButton.addEventListener('click', () => {
    gettingVideoOverlay.remove()

    const main = document.querySelector('.getting-video')

    const video = document.createElement('video')
    video.src = './assets/videos/getting.mp4'
    video.autoplay = true
    video.controls = true

    video.style.width = '100%'
    video.style.height = '100%'

    main.appendChild(video)
  })
}